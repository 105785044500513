import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import toast from 'react-hot-toast';

function Form() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    toast.success('Заявка отправлена!')
    emailjs.sendForm('service_w0y24qo', 'template_6fw4g1w', form.current, 'user_1abGIUzZEyzAPP9f7Yy4Q')
      .then((result) => {
          console.log(result.text);
          
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <form ref={form} onSubmit={sendEmail}>
    <div
      id="form"
      className="flex flex-col justify-center items-center mt-10 p-20 space-y-12 max-w-2xl mx-auto"
    >
      <div className="text-2xl font-bold">Оставить заявку</div>
      
        <div className="flex flex-col space-y-6 w-full">
          <input
            type="text"
            id="name"
            name="user_name"
            placeholder="Ваше имя"
            className="rounded-md border border-[#91AD58] px-4 py-2 focus:border-[#d08247] outline-none"
          />
          <input
            type="email"
            id="email"
            name="user_email"
            placeholder="Ваша почта"
            className="rounded-md border border-[#91AD58] px-4 py-2 focus:border-[#d08247] outline-none"
          />
          <textarea
            rows="3"
            placeholder="Сообщение"
            name='message'
            className="rounded-md border border-[#91AD58] px-4 py-2 focus:border-[#d08247] outline-none"
          />
          <button
            type="submit"
            className="rounded-md bg-[#d08247] py-2 text-white font-bold hover:bg-[#803a04] "
          >
            Отправить
          </button>
        </div>
    </div>
    </form>
  );
}
export default Form;
