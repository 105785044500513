import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

import Navbar from "./Navbar";
import Footer from "./Footer";

import { BsArrowLeft } from "react-icons/bs";

import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";

import { GrDocumentDownload } from "react-icons/gr";

import products from "./data";

function SingleProduct() {
  const { productId } = useParams();
  const product = products.find((product) => product.id === productId);

  const slides = product.image;

  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  return (
    <section>
      <Navbar />
      <Link to="/products" className="flex items-center space-x-5 px-10 pt-10">
        <BsArrowLeft />
        <p>Вернуться обратно</p>
      </Link>
      <section class="text-gray-600 body-font overflow-hidden">
        <div class="container px-5 py-20 mx-auto">
          <div class="w-full mx-auto flex flex-wrap md:flex-nowrap md:flex-row gap-8">
            <div className="max-w-xl h-[480px] w-full m-auto relative group">
              <div
                style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
                className="w-full h-full rounded-2xl bg-center bg-cover duration-500"
              ></div>
              <div
                onClick={prevSlide}
                className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer"
              >
                <BsChevronCompactLeft size={30} />
              </div>
              <div
                onClick={nextSlide}
                className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer"
              >
                <BsChevronCompactRight size={30} />
              </div>
              <div className="flex top-4 justify-center py-2">
                {slides.map((slide, slideIndex) => (
                  <div
                    key={slideIndex}
                    onClick={() => goToSlide(slideIndex)}
                    className="text-2xl cursor-pointer"
                  >
                    <RxDotFilled />
                  </div>
                ))}
              </div>
            </div>
            <div class="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
              <h1 class="text-black text-2xl title-font font-medium mb-1">
                {product.name}
              </h1>
              <p class="leading-relaxed text-sm">{product.description}</p>
            </div>
          </div>
        </div>
        <div className="flex w-full justify-center p-16 items-center gap-5">
          <GrDocumentDownload />
          <a href={product.document_path} className="font-bold">
            Скачать спецификацию
          </a>
        </div>
      </section>
      <Footer />
    </section>
  );
}

export default SingleProduct;
