const products = [
  {
    id: "1",
    name: 'Анализаторы взвешенных частиц в атмосферном воздухе автоматические "Борей" (стационарный)',
    short_description: "opisanie1",
    description:
      "Анализаторы взвешенных частиц в атмосферном воздухе автоматический «Борей» предназначены для измерения массовой концентрации и плотности взвешенных (аэрозольных частиц) частиц (общая концентрация взвешенных частиц TSP, фракции PM-10, PM-2.5, PM-1.0).   Область применения – мониторинг атмосферного воздуха, санитарно-гигиенический и технологический контроль воздушной среды, обеспечение промышленной безопасности, контроль среды в чистых помещениях и на различных объектах, обеспечение безопасных условий труда.",
    document_path: "/documents/borey.docx",
    image: [
      {
        url: "/images/borey-1.png",
      },
      {
        url: "/images/borey-2.jpeg",
      },
    ],
    category: "air",
  },
  {
    id: "2",
    name: "Topas — стационарный анализатор взвешенных частиц",
    short_description: "opisanie1",
    description:
      "Topas — стационарный анализатор взвешенных частиц, предназначенный для долгосрочного измерения на одной точке. Возможно объединение нескольких приборов в сеть с помощью GSM или радио модемов. Topas позволяет одновременно измерять концентрации частиц фракций TSP, PM10, PM2.5 и PM1. Встроенный эталонный фильтр используется для проверки и калибровки анализаторов с помощью гравиметрического метода. Прибор выполняет сохранение концентраций частиц во встроенную память. Также пылемер Topas оснащен датчиками скорости и направления ветра, температуры, влажности, осадков, а также разъемами для подключения шумомера или газоанализатора. Применение Анализатор пыли Topas находит применение в лабораториях, а также цехах на производстве в различных отраслях промышленности для контроля воздуха атмосферы и рабочей зоны. Особенности Одновременное измерение концентраций частиц фракций TSP, PM10, PM2.5 и PM1.",
    document_path: "/documents/topas.pdf",
    image: [
      {
        url: "/images/topas-1.jpeg",
      },
      {
        url: "/images/topas-2.jpeg",
      },
    ],
    category: "air",
  },
  {
    id: "3",
    name: "Монитор окружающей среды DustMate",
    short_description: "opisanie1",
    description:
      "Монитор окружающей среды DustMate - это портативный детектор пыли и дыма, который идеально подходит для кратковременного отбора проб. Универсальное и практичное, это портативное устройство идеально подходит для кратковременного тестирования качества воздуха в зданиях, на рабочих местах и на обочинах дорог. Благодаря очень быстрому времени отклика, он обеспечивает точные показания и может определять местонахождение источников пыли и паров в воздухе даже при очень низких концентрациях. Высокоэффективный прибор для мониторинга качества воздуха, DustMate измеряет TSP, PM10, PM2.5 и PM1 одновременно в режиме реального времени. Он также будет непрерывно отображать концентрацию частиц",
    document_path: "/documents/dustmate.docx",
    image: [
      {
        url: "/images/dustmate.jpg",
      },
    ],
    category: "air",
  },
  {
    id: "4",
    name: "Автоматическая система отбора проб PNS T-DM",
    short_description: "opisanie1",
    description:
      "Эталонная система отбора проб для непрерывного мониторинга твердых частиц (PM10, PM2,5 или PM1) с автоматической сменой фильтра. \n PNS T-DM сочетает в себе пробоотборник малого/среднего объема (LVS или MVS) и сменный фильтр в корпусе из нержавеющей стали. Она собирает мелкие частицы на фильтрах для отбора проб в соответствии с EN 12341:2014 (PM10 и PM2.5). Для этой цели вакуумный насос всасывает окружающий воздух, и система фракционирует частицы, находящиеся в воздухе, на входе для отбора проб. Затем воздух, содержащий желаемую фракцию мелких частиц, проходит через фильтр, где частицы собираются и становятся доступными для последующей гравиметрической оценки или анализа. Автоматическая смена фильтров с приводом Geneva и двумя параллельными кассетами фильтров обеспечивает последовательную серию до 18 или 24 циклов отбора проб. Объемный расход измеряется с помощью диафрагмы и регулируется электронным способом с точностью ≤ 2%",
    document_path: "/documents/pns18.docx",
    image: [
      {
        url: "/images/pns18.png",
      },
    ],
    category: "air",
  },
  {
    id: "5",
    name: "Газоанализаторы Беркут CH2O",
    short_description: "opisanie1",
    description:
      "Газоанализаторы Беркут CH2O предназначены для измерений содержания формальдегида (СН2O) в атмосферном воздухе и воздухе рабочей зоны.",
    document_path: "/documents/berkut_02.docx",
    image: [
      {
        url: "/images/ch2o_front.jpeg",
      },
      {
        url: "/images/ch2o_back.jpeg",
      },
    ],
    category: "gaz",
  },
  {
    id: "6",
    name: "Газоанализаторы Беркут CO2",
    short_description: "opisanie1",
    description:
      "Газоанализатор Беркут СО2 предназначен для измерения объемной доли диоксида углерода в атмосферном воздухе и воздухе рабочей зоны. Анализатор представляет собой автоматический прибор непрерывного действия.",
    document_path: "/documents/berkut_02.docx",
    image: [
      {
        url: "/images/co2_front.jpeg",
      },
      {
        url: "/images/co2_back.jpeg",
      },
    ],
    category: "gaz",
  },
  {
    id: "7",
    name: "Газоанализаторы Беркут H2S",
    short_description: "opisanie1",
    description:
      "Газоанализатор Беркут H2S предназначен для измерения массовой концентрации или объемной доли сероводорода в воздушных средах. Газоанализатор представляет собой стационарный, автоматический, показывающий прибор непрерывного действия.",
    document_path: "/documents/berkut_02.docx",
    image: [
      {
        url: "/images/h2s_front.jpeg",
      },
      {
        url: "/images/h2s_back.jpeg",
      },
    ],
    category: "gaz",
  },
  {
    id: "8",
    name: "Газоанализатор аммиака Беркут NH3",
    short_description: "opisanie1",
    description:
      "Газоанализатор аммиака Беркут NH3 способен быстро определить массовую или объемную долю аммиака в атмосферном воздухе и воздухе рабочей зоны.",
    document_path: "/documents/berkut_02.docx",
    image: [
      {
        url: "/images/nh3_front.jpeg",
      },
      {
        url: "/images/nh3_middle.jpeg",
      },
      {
        url: "/images/nh3_back.jpeg",
      },
    ],
    category: "gaz",
  },
  {
    id: "9",
    name: "Автоматический показывающий прибор Беркут NO ",
    short_description: "opisanie1",
    description:
      "Автоматический показывающий прибор, Беркут NO непрерывного действия, предназначенный для измерения массовой концентрации и объемной доли оксида в атмосферном воздухе и воздухе рабочей зоны.",
    document_path: "/documents/berkut_02.docx",
    image: [
      {
        url: "/images/no_front.jpeg",
      },
      {
        url: "/images/no_back.jpeg",
      },
    ],
    category: "gaz",
  },
  {
    id: "10",
    name: 'Анализаторы взвешенных частиц в атмосферном воздухе автоматические "Борей" (переносной)',
    short_description: "opisanie1",
    description:
      "Анализаторы взвешенных частиц в атмосферном воздухе автоматический «Борей» предназначены для измерения массовой концентрации и плотности взвешенных (аэрозольных частиц) частиц (общая концентрация взвешенных частиц TSP, фракции PM-10, PM-2.5, PM-1.0).   Область применения – мониторинг атмосферного воздуха, санитарно-гигиенический и технологический контроль воздушной среды, обеспечение промышленной безопасности, контроль среды в чистых помещениях и на различных объектах, обеспечение безопасных условий труда.",
    document_path: "/documents/borey.docx",
    image: [
      {
        url: "/images/borei_perenos_1.png",
      },
      {
        url: "/images/borei_perenos_2.jpeg",
      },
    ],
    category: "air",
  },
  {
    id: "11",
    name: 'Автоматическая система отбора проб PNS DMC',
    short_description: "opisanie1",
    description:
      "Автоматическая система отбора проб для непрерывного мониторинга твердых частиц (PM10, PM2,5 или PM1) со сменным фильтром с двумя кассетами. Система отбора проб PNS DMC основана на технологии отбора проб малого / среднего объема (LVS или MVS) и оснащена автоматической сменой фильтров. Компактная конструкция разработана для установки в 19-дюймовую стойку.",
    document_path: "/documents/dmc.pptx",
    image: [
      {
        url: "/images/pmc_dmc.jpeg",
      }
    ],
    category: "air",
  },
  {
    id: "12",
    name: 'Мобильный пылезащитный монитор MDM 2',
    short_description: "opisanie1",
    description:
      "Мобильный измерительный прибор с датчиком рассеянного света для мониторинга концентрации ТЧ 2.5 в режиме реального времениКомпактный и легкий ручной измерительный прибор MDM-2 (мобильный пылевой монитор) измеряет текущую концентрацию PM2.5 на месте измерения в режиме реального времени. В нем применяется фотометрический метод с использованием рассеянного света (нефелометрия). Графический индикатор тренда показывает изменения концентрации PM2.5.",
    document_path: "/documents/mdm.pptx",
    image: [
      {
        url: "/images/mdm_1.jpeg",
      },
      {
        url: "/images/mdm_2.jpeg",
      }
    ],
    category: "air",
  },
];

export default products;