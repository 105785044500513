import Footer from "./Footer";
import Navbar from "./Navbar";

import { Link } from "react-router-dom";
import products from "./data";

function Products() {
  const gaz = products.filter((obj) => obj.category === "gaz");
  const air = products.filter((obj) => obj.category === "air");
  return (
    <>
      <Navbar />
      <div className="py-20 md:px-20 px-10">
      <div>
          <h3 className="text-xl font-bold py-10 px-10">Анализаторы пыли</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 justify-items-center items-center gap-8">
            {air.map((product) => {
              return (
                <Link to={`/products/${product.id}`}>
                  <article
                    key={product.name}
                    className="space-y-3 max-w-[240px] text-sm"
                  >
                    <img
                      className="w-[240px] h-[180px] object-cover rounded-xl"
                      src={product.image[0].url}
                      alt={product.name}
                    ></img>
                    <h5>{product.name}</h5>
                  </article>
                </Link>
              );
            })}
          </div>
        </div>
        <div>
          <h3 className="text-xl font-bold py-10 px-10">Газоанализаторы</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 justify-items-center items-center gap-8">
            {gaz.map((product) => {
              return (
                <Link to={`/products/${product.id}`}>
                  <article
                    key={product.name}
                    className="space-y-3 max-w-[240px] text-sm"
                  >
                    <img
                      className="w-[240px] h-[180px] object-cover rounded-xl"
                      src={product.image[0].url}
                      alt={product.name}
                    ></img>
                    <h5>{product.name}</h5>
                  </article>
                </Link>
              );
            })}
          </div>
        </div>
        
      </div>
      <Footer />
    </>
  );
}

export default Products;
